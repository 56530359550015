import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import Vitrine from '../images/vitrine.png'
import ECommerce from '../images/eCommerce.png'
import Platform from '../images/plateforme.png'
import Hosting from '../images/server.png'
import Maintenance from '../images/network.png'
import Formation from '../images/hand.png'
import Prototype from '../images/prototype.png'
import _ from 'lodash'
import ServiceCard from './ServiceCard'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next"

const styles = theme => ({
  title: {
    margin: '20px 0',
    color: theme.palette.text.primary
  }
})

const Services = ({ classes }) => {
  const [t] = useTranslation("HomePage")

  const services = [
    {
      title: t('services.showcase-website-title'),
      description: t('services.showcase-website-description'),
      icon: Vitrine
    },
    {
      title: t('services.e-commerce-website-title'),
      description: t('services.e-commerce-website-description'),
      icon: ECommerce
    },
    {
      title: t('services.custom-platform-title'),
      description: t('services.custom-platform-description'),
      icon: Platform
    }
  ]

  const services2 = [
    {
      title: t('services.prototyping-title'),
      description: t('services.prototyping-description'),
      icon: Prototype
    },
    {
      title: t('services.hosting-title'),
      description: t('services.hosting-description'),
      icon: Hosting
    },
    {
      title: t('services.maintenance-title'),
      description: t('services.maintenance-description'),
      icon: Maintenance
    },
    {
      title: t('services.training-title'),
      description: t('services.training-description'),
      icon: Formation
    }
  ]

  return (
    <Grid container direction={'column'}>
      <Typography data-sal="slide-up" className={classes.title} variant={'h5'}>{t('services.help-you')}</Typography>

      <Grid container item justify={'space-evenly'} xs={12}>
        {_.map(services, (service, id) => {
          return (
            <ServiceCard
              key={id}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          )
        })}
      </Grid>

      <Typography data-sal="slide-up" className={classes.title} variant={'h5'}>{t('services.accompanying-you')}</Typography>

      <Grid container item justify={'space-evenly'} xs={12}>
        {_.map(services2, (service, id) => {
          return (
            <ServiceCard
              key={id}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles) (Services)
