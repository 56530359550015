import React from 'react'
import { withStyles, Grid, Typography, CardContent, Card } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = {
  card: {
    minWidth: 275,
    maxWidth: 400,
    marginBottom: 30,
    backgroundColor: '#222',
    border: '1px solid black'
  },
  title: {
    fontSize: 20
  },
  media: {
    width: '100%'
  }
}

const ServiceCard = props => {
  const { classes, title, description, icon } = props

  return (
    <Card data-sal="slide-up" data-sal-delay="200" className={classes.card} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={2}>
            <img className={classes.media} src={icon} alt={title}/>
          </Grid>
          <Grid item xs={10} >
            <Typography className={classes.title} align={'center'} color="textSecondary">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

ServiceCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired
}

export default withStyles(styles)(ServiceCard)
