import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from "gatsby-image"

const ImageSection = ({ className }) => (
  <StaticQuery
    query={graphql`
  query {
    file(relativePath: { eq: "picture.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 250){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
    render={data => {
      // Set ImageData.
      const imageData = data.file.childImageSharp.fluid
      return (
        <Img
          className={className}
          fluid={imageData}
        />
      )
    }}
  />
)

const StyledImageSection = styled(ImageSection)`
  width: 60%;
  max-width: 250px;
  border-radius: 50%;
  background-position: center;
  border: 2px solid red;
  box-shadow: Opx 0px 5px black
`

export default StyledImageSection
