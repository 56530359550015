import React from "react"
import PropTypes from "prop-types"
import Image from '../components/image'
import { withStyles, Grid, Typography } from "@material-ui/core"
import _ from 'lodash'
import { useTranslation } from "react-i18next"

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  text: {
    color: 'white',
    fontSize: '17px',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  name: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '40px',
    margin: '20px 0',
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  technoContainer: {
    backgroundColor: 'red',
    borderRadius: 5,
    marginTop: 20,
    marginRight: 20,
    padding: '3px 8px',
    boxShadow: 'inset 3px 2px 8px #222'
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30
    }
  }
})

function About({ classes }) {
  const { t } = useTranslation("HomePage")

  const techno = ['Javascript', 'React', 'NodeJS', 'Firebase', 'Material-UI', 'Bootstrap', 'Redux', 'Express']

  const clock = `${new Date().getHours()}:${new Date().getMinutes()}`

  return (
    <Grid data-sal="fade" container>
      <Grid className={classes.img} data-sal="slide-down" data-sal-easing="ease" container justify={"center"} item md={6} xs={12}>
        <Image/>
      </Grid>

      <Grid container direction={'column'} item md={6} xs={12} justify={'center'} alignItems={'center'}>
        <Typography component={'p'} className={classes.text}>{clock > '18:00' && clock < '23:59' ? t('about.good-evening') : t('about.hello')} {t('about.i-am')}</Typography>
        <Typography data-sal="slide-left" data-sal-easing="ease" className={classes.name}>Maxime Pochet</Typography>
        <Typography component={'p'} className={classes.text}>{t('about.web-developer')}</Typography>
      </Grid>
      <Grid container justify={'center'} className={classes.container}>
        {_.map(techno, (name, idx) =>
          <div data-sal="slide-down" data-sal-easing="ease" key={idx} className={classes.technoContainer}>
            <Typography  component={'p'} className={classes.text}>{name}</Typography>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

About.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles) (About)
