import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/About"
import Services from "../components/Services"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <About/>
    <Services/>
  </Layout>
)

export default IndexPage
